<template>
  <div data-app>
    <v-form v-model="formValid" ref="form">
      <v-container>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="6" sm="6" md="6" style="color: #e33354" class="ml-4">
                <h3>{{ cardTitle }}</h3>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  v-model="selectedLocale"
                  :items="languages"
                  :disabled="languages.length == 1"
                >
                  <template slot="selection" slot-scope="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="selectedLocale.flag" alt="" />
                    </span>
                    {{ slotProps.item.name }}
                  </template>
                  <template v-slot:item="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="slotProps.item.flag" alt="" />
                    </span>
                    <span class="navi-text">{{ slotProps.item.name }}</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <b-tabs content-class="mt-3">
                  <b-tab
                    :title="$t('FORM_INPUT_NAMES.default_settings')"
                    active
                  >
                    <v-row>
                      <v-col cols="12" sm="8" md="8">
                        <v-text-field
                          v-if="formModel"
                          v-model="formModel.name"
                          :label="$t('FORMS.name')"
                          :rules="nameRules"
                          :id="dynamicID"
                          :error-messages="messages['name']"
                          @keyup="messages['name'] = ''"
                          disabled
                        ></v-text-field>

                        <CustomFieldComponent
                          v-if="formModel && formModel.custom_fields"
                          :config="{
                            type: 'text',
                            label: 'FORM_INPUT_NAMES.gtm',
                          }"
                          customFieldName="gtm"
                          :value="formModel.custom_fields.gtm"
                          @handleChangeCustomFieldValue="
                            handleChangeCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <CustomFieldComponent
                              v-if="formModel && formModel.custom_fields"
                              :config="{
                                type: 'text',
                                label: 'FORM_INPUT_NAMES.recaptcha_site_key',
                              }"
                              customFieldName="recaptcha_site_key"
                              :value="
                                formModel.custom_fields.recaptcha_site_key
                              "
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                            >
                            </CustomFieldComponent>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <CustomFieldComponent
                              v-if="formModel && formModel.custom_fields"
                              :config="{
                                type: 'text',
                                label: 'FORM_INPUT_NAMES.recaptcha_site_secret',
                              }"
                              customFieldName="recaptcha_site_secret"
                              :value="
                                formModel.custom_fields.recaptcha_site_secret
                              "
                              @handleChangeCustomFieldValue="
                                handleChangeCustomFieldValue
                              "
                            >
                            </CustomFieldComponent>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-autocomplete
                          v-if="formModel && formModel.custom_fields"
                          v-model="formModel.custom_fields.selectedLanguages"
                          :items="locales"
                          chips
                          multiple
                          :label="$t('FORMS.select_site_language_order')"
                        >
                          <template #selection="data">
                            <draggable
                              :id="data.index"
                              :list="formModel.custom_fields.selectedLanguages"
                              v-bind="dragOptionsChips"
                              :move="move"
                              @change="change"
                            >
                              <v-chip
                                draggable
                                v-model="
                                  formModel.custom_fields.selectedLanguages[
                                    data.index
                                  ]
                                "
                                :key="data.item"
                                close
                                color="bg-gray-100"
                                @click:close="
                                  deleteChip(
                                    data.item,
                                    formModel.custom_fields.selectedLanguages
                                  )
                                "
                                @mousedown.stop
                                @click.stop
                              >
                                {{ data.item }}
                              </v-chip>
                            </draggable>
                          </template>
                        </v-autocomplete>
                        <draggable
                          v-model="formModel.custom_fields.selectedLanguages"
                          v-bind="dragOptionsChips"
                        >
                        </draggable>
                        {{ $t("FORM_INPUT_NAMES.select_public_languages") }}
                        <div v-if="formModel && formModel.custom_fields">
                          <v-checkbox
                            class="mt-0"
                            v-for="(checkBox, index) in publicLocales"
                            v-bind:key="index"
                            v-model="formModel.custom_fields.publicLanguages"
                            :label="publicLocales[index]['value'] || []"
                            :value="publicLocales[index]['value'] || []"
                          ></v-checkbox>
                        </div>

                        <v-col cols="12" sm="6" md="6">
                          <CustomFieldComponent
                            v-if="formModel && formModel.custom_fields"
                            :config="{
                              type: 'multi_select',
                              items: publicLocales.map((item) => item.value),
                              label: $t(
                                'FORM_INPUT_NAMES.foreignFallbackLocale'
                              ),
                              multiple: false,
                            }"
                            customFieldName="foreignFallbackLocale"
                            :value="
                              formModel.custom_fields.foreignFallbackLocale
                            "
                            @handleChangeCustomFieldValue="
                              handleChangeCustomFieldValue
                            "
                          >
                          </CustomFieldComponent>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12"> SMTP: </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <CustomFieldComponent
                          v-if="formModel && formModel.custom_fields"
                          :config="{
                            type: 'text',
                            label: 'FORM_INPUT_NAMES.sender_name',
                          }"
                          customFieldName="name"
                          :value="formModel.custom_fields.name"
                          @handleChangeCustomFieldValue="
                            handleChangeCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <CustomFieldComponent
                          v-if="formModel && formModel.custom_fields"
                          :config="{
                            type: 'text',
                            label: 'FORM_INPUT_NAMES.sender_email',
                          }"
                          customFieldName="email"
                          :value="formModel.custom_fields.email"
                          @handleChangeCustomFieldValue="
                            handleChangeCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <CustomFieldComponent
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          customFieldName="logo_header"
                          :value="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].logo_header || ''
                          "
                          :config="{
                            type: 'media_selector',
                            maxFiles: 1,
                            selectButtonText:
                              'FORM_INPUT_NAMES.select_header_logo',
                            initialColumnClass:
                              'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                          }"
                          @handleChangeCustomFieldValue="
                            handleChangeTranslatedCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <CustomFieldComponent
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          customFieldName="logo_footer"
                          :value="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].logo_footer || ''
                          "
                          :config="{
                            type: 'media_selector',
                            maxFiles: 1,
                            selectButtonText:
                              'FORM_INPUT_NAMES.select_footer_logo',
                            initialColumnClass:
                              'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                          }"
                          @handleChangeCustomFieldValue="
                            handleChangeTranslatedCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <CustomFieldComponent
                          v-if="formModel && formModel.custom_fields"
                          customFieldName="favicon"
                          :value="formModel.custom_fields.favicon || ''"
                          :config="{
                            type: 'media_selector',
                            maxFiles: 1,
                            selectButtonText: 'FORM_INPUT_NAMES.select_favicon',
                            initialColumnClass:
                              'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                          }"
                          @handleChangeCustomFieldValue="
                            handleChangeCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="6">
                        <CustomFieldComponent
                          v-if="formModel && formModel.custom_fields"
                          customFieldName="logo"
                          :value="formModel.custom_fields.logo || ''"
                          :config="{
                            type: 'media_selector',
                            maxFiles: 1,
                            selectButtonText: 'FORM_INPUT_NAMES.select_logo',
                            initialColumnClass:
                              'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                          }"
                          @handleChangeCustomFieldValue="
                            handleChangeCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                      </v-col> -->
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <CustomFieldComponent
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          customFieldName="og_image"
                          :value="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].og_image || ''
                          "
                          :config="{
                            type: 'media_selector',
                            maxFiles: 1,
                            selectButtonText: 'FORM_INPUT_NAMES.og_image',
                            initialColumnClass:
                              'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                          }"
                          @handleChangeCustomFieldValue="
                            handleChangeTranslatedCustomFieldValue
                          "
                        >
                        </CustomFieldComponent>
                      </v-col>
                    </v-row>
                  </b-tab>
                  <b-tab :title="$t('FORM_INPUT_NAMES.company_data')">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyName
                          "
                          :label="$t('FORM_INPUT_NAMES.company_name')"
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyTaxNumber
                          "
                          :label="$t('FORM_INPUT_NAMES.company_tax_number')"
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyRegistrationNumber
                          "
                          :label="
                            $t('FORM_INPUT_NAMES.company_registration_number')
                          "
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyBankAccountNumber
                          "
                          :label="
                            $t('FORM_INPUT_NAMES.company_bank_account_number')
                          "
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyBankIban
                          "
                          :label="$t('FORM_INPUT_NAMES.company_bank_iban')"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyAddress
                          "
                          :label="$t('FORM_INPUT_NAMES.company_address')"
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyPhone
                          "
                          :label="$t('FORM_INPUT_NAMES.company_phone')"
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyFax
                          "
                          :label="$t('FORM_INPUT_NAMES.company_fax')"
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyBankSwift
                          "
                          :label="$t('FORM_INPUT_NAMES.company_bank_swift')"
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companyEmail
                          "
                          :label="$t('FORM_INPUT_NAMES.company_email')"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        {{ $t("FORM_INPUT_NAMES.company_slogen") }}
                        <ckeditor
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].companySlogen
                          "
                          :config="editorConfig"
                        ></ckeditor>
                      </v-col>
                    </v-row>
                  </b-tab>
                  <b-tab :title="$t('FORM_INPUT_NAMES.social_links')">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].facebook
                          "
                          label="Facebook"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].linkedin
                          "
                          label="LinkedIn"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].youtube
                          "
                          label="Youtube"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-if="
                            formModel &&
                            formModel.custom_fields &&
                            formModel.custom_fields.translations &&
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ]
                          "
                          v-model="
                            formModel.custom_fields.translations[
                              selectedLocale.lang
                            ].instagram
                          "
                          label="Instagram"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </b-tab>

                  <b-tab title="Dashboard iframe">
                    <!-- <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'textarea',
                        label: 'FORM_INPUT_NAMES.dashboard',
                      }"
                      customFieldName="dashboard"
                      :value="formModel.custom_fields.dashboard"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent> -->
                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'accordion',
                        label: 'FORM_INPUT_NAMES.dashboard',
                        questionLabel: 'FORMS.title',
                        answerLabel: 'FORMS.text',
                        simpleText: true,
                      }"
                      customFieldName="dashboards"
                      :value="formModel.custom_fields.dashboards"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </b-tab>
                </b-tabs>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <!-- <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
                <h3>
                  {{ $t("FORMS.company_settings") }}
                </h3>
              </v-col>
            </v-row> -->
          </v-card-text>

          <v-card-actions class="mb-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="handleCloseModalForm">
              {{ $t("FORMS.cancel") }}
            </v-btn>
            <v-btn
              v-if="
                (permissionCan('create') && !formModel.id) ||
                permissionCan('update')
              "
              color="primary"
              elevation="2"
              @click="handleSaveModalForm"
            >
              {{ $t("FORMS.save") }}
            </v-btn>
          </v-card-actions>
          <v-overlay :value="loader">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
        <v-snackbar
          v-model="snackbar.show"
          :timeout="snackbar.timeout"
          color="primary"
        >
          {{ snackbar.text }}
        </v-snackbar>
      </v-container>
    </v-form>
  </div>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
// import { mapGetters } from "vuex";

import SiteService from "@/core/services/site.service.js";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import draggable from "vuedraggable";

export const ENDPOINT = "site/";

export const PERMISSION_TO = "system.site.";

const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "title",
  "companyName",
  "companyTaxNumber",
  "companyRegistrationNumber",
  "companyBankAccountNumber",
  "companyAdress",
  "companyPhone",
  "companyFax",
  "companyEmail",
  "companyGmap",
  "companySlogen",
  "facebook",
  "linkedin",
  "youtube",
  "instagram",
  "og_image",
  "logo_header",
  "logo_footer",
];
export const INITIAL_CUSTOM_FIELDS = {
  name: "",
  email: "",
  favicon: "",
  // logo: "",
  selectedLanguages: [],
  publicLanguages: [],
  gtm: "",
  dashboards: [],
  recaptcha_site_key: "",
  recaptcha_site_secret: "",
  translations: {},
  companyName: "",
  companyTaxNumber: "",
  companyRegistrationNumber: "",
  companyBankAccountNumber: "",
  companyBankSwift: "",
  companyBankIban: "",
  companyAdress: "",
  companyPhone: "",
  companyFax: "",
  companyEmail: "",
  companyGmap: "",
  companySlogen: "",
  foreignFallbackLocale: "",
  facebook: "",
  linkedin: "",

  // og_type: "",
  // og_title: "",
  // og_description: "",
  // og_url: "",
  // og_site_name: "",
  // og_image: "",
};

export const initialFormData = () => ({
  id: null,
  name: "",
  locales: [],
  translations: {},
  custom_fields: INITIAL_CUSTOM_FIELDS,
});

export default {
  name: "Sites",
  components: { CustomFieldComponent, draggable },

  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.PAGE_SETTINGS"),

      routePath: "/cms/cookieManagement/settings/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),

      dialogDelete: false,
      endPoint: ENDPOINT,

      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      loader: false,
      formModel: Object.assign({}, initialFormData()),
      messages: [],
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
      // selectedSync: [],
      og_types: ["website", "article"],

      snackbar: {
        show: false,
        text: this.$t("SNACK_BAR.saved"),
        timeout: 1000,
      },
    };
  },

  computed: {
    // ...mapGetters(["siteCollection", "statuses"]),
    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },
    locales() {
      if (!this.$store.getters.locales) return [];
      return this.$store.getters.locales;
    },

    publicLocales() {
      if (!this.formModel.custom_fields.selectedLanguages) return [];
      return this.formModel.custom_fields.selectedLanguages.map((item) => {
        return { label: item, value: item };
      });
    },

    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
  },

  watch: {
    publicLocales(val) {
      let publicLanguages = this.formModel.custom_fields.publicLanguages;

      this.formModel.custom_fields.publicLanguages = [];

      val.forEach((lang) => {
        if (publicLanguages.includes(lang.value)) {
          this.formModel.custom_fields.publicLanguages.unshift(lang.value);
        }
      });
    },
  },

  methods: {
    loadModel() {
      this.selectedLocale = i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      });
      this.loader = true;
      this.formModel = Object.assign({}, initialFormData());
      ApiService.get(ENDPOINT + SiteService.getActiveSiteId())
        .then(({ data }) => {
          this.languages.forEach((language) => {
            if (!data.custom_fields) {
              data.custom_fields = {};
            }
            if (!data.custom_fields.translations)
              data.custom_fields.translations = {};
            // data.custom_fields.translations = {};
            if (!data.custom_fields.translations[language.lang]) {
              data.custom_fields.translations[language.lang] = {};
              data.custom_fields.translations[language.lang]["title"] = "";
            }
          });

          this.formModel = Object.assign({}, data);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();
      if (this.formValid) {
        this.resetErrorMessages();

        this.loader = true;

        if (model.id) {
          // model.custom_fields.translations["hu"]["title"] = "magyar";

          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
              this.snackbar.show = true;
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    toggle: function (todo) {
      todo.done = !todo.done;
    },
    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change: function (value) {
      if (value.removed) {
        // insert
        this.formModel.custom_fields.selectedLanguages.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.formModel.custom_fields.selectedLanguages[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.formModel.custom_fields.selectedLanguages.splice(
            this.dragged.from,
            1
          );
        // RTL
        else
          this.formModel.custom_fields.selectedLanguages.splice(
            this.dragged.from + 1,
            1
          );
      }
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    handleChangeTranslatedCustomFieldValue(customField, newValue) {
      // if (
      //   this.initialFormData().custom_fields &&
      //   Object.keys(this.initialFormData().custom_fields).indexOf(
      //     customField
      //   ) != -1
      // ) {
      //   this.formModel.custom_fields[customField] = newValue;

      //   return true;
      // }

      if (
        this.initialTransaltedCustomFields.indexOf(customField) != -1 &&
        this.formModel.custom_fields.translations[this.selectedLocale.lang]
      ) {
        this.formModel.custom_fields.translations[this.selectedLocale.lang][
          customField
        ] = newValue;
      }
      // else {
      //   this.formModel.translations[this.selectedLocale.lang][customField] =
      //     newValue;
      // }
      let formModel = this.formModel;
      this.formModel = null;
      this.formModel = formModel;
    },
  },

  mounted() {
    this.loadModel();

    // this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
